import { SortState } from "../../../../utils";

// All this file is a "proxy" with minified functionalities from `SortState`
export type { StateId } from "../../../../utils/sort-state";
export { transformToQuery } from "../../../../utils/sort-state";

/** Current allowed directions for {@link TableSortable} */
export const DIRECTIONS = ["desc", "asc"] as const;
/** Directions for {@link TableSortable} */
export type Direction = (typeof DIRECTIONS)[number];

/** Sort state for {@link TableSortable}. With currently forced directions */
export type State<K extends SortState.StateId> = SortState.State<K, Direction>;

/** Params for {@link update} */
export type UpdateParams<K extends SortState.StateId> = Pick<
	SortState.UpdateParams<K, Direction>,
	"id"
>;
/**
 * Updates the sort-state of the columns
 *
 * @see SortState.update
 * @param states sort states to update
 * @param params to make the update with
 * @returns updated states
 */
export function update<K extends SortState.StateId>(
	states: ReadonlyArray<State<K>>,
	params: UpdateParams<K>,
) {
	return SortState.update(states, { ...params, directions: DIRECTIONS });
}

/** Params for {@link extractFromQuery} */
export type ExtractFromQueryParams<K extends SortState.StateId> = Pick<
	SortState.ExtractFromQueryParams<K, Direction>,
	"columns" | "prefix"
>;

/**
 * Extracts from a record (supposedly a router's query) the sorting data
 * 	with TableSort known directions
 *
 * @see SortState.extractFromQuery
 * @param query to extract the sorting data from
 * @param params for extraction
 * @returns array of sorted columns
 */
export function extractFromQuery<const K extends SortState.StateId>(
	query: SortState.ExtractableQuery,
	params: ExtractFromQueryParams<K>,
) {
	return SortState.extractFromQuery(query, {
		...params,
		directions: DIRECTIONS,
	});
}
