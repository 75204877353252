/**
 * Pagination params when used as in a query (e.g. with our backend)
 */
export interface QueryPagination {
	/** The number of items per page */
	limit: number;
	/** The number of items skipped in a query */
	skip: number;
}

/**
 * Generic pagination params (e.g. for the {@link Pagination} component)
 */
export interface PagePagination {
	/** The current page (starts at 1) */
	page: number;
	/** The number of items per page */
	pageSize: number;
}

/**
 * Function to convert 'query' pagination params to 'page' pagination params
 *
 * @param limit The number of items per page
 * @param skip The number of items skipped in a query
 * @returns PagePagination
 */
export function queryToPage(
	limit: QueryPagination["limit"],
	skip: QueryPagination["skip"],
): PagePagination {
	return {
		page: Math.floor(skip / limit) + 1,
		pageSize: limit,
	};
}

/**
 * Function to convert 'page' pagination params to 'query' pagination params
 *
 * @param page The current page (starts at 1)
 * @param pageSize The number of items per page
 * @returns QueryPagination
 */
export function pageToQuery(
	page: PagePagination["page"],
	pageSize: PagePagination["pageSize"],
): QueryPagination {
	return {
		limit: pageSize,
		skip: (page - 1) * pageSize,
	};
}
