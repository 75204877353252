import classnames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

import { DataTableColumnId } from "./common";

/** Row column definition for {@link RowDataProps} */
export interface RowColumn<T, K extends DataTableColumnId> {
	/** Table cell to generate with the given data */
	cell: (data: T) => ReactNode;
	/** id/name of the column */
	id: K;
}

/** Definition (config) for a {@link RowData} */
export interface RowDataDef<T> {
	/** Additional className to add to the row */
	className?: string;
	/**
	 * Is the row for this data clickable.
	 * Setting it to `false` disables {@link onRowClick} event
	 *
	 * @default true
	 */
	clickable?: boolean;
	/** The "data" to show */
	data: T;
}

/** Props for {@link RowData} */
export interface RowDataProps<T, K extends DataTableColumnId>
	extends RowDataDef<T> {
	/** Columns definition of the row */
	columns: ReadonlyArray<RowColumn<T, K>>;
	/**
	 * Event when a row receives a click event. (= `onClick`)
	 *
	 * Note: if the click is on a "link-child", then this handler is ignored.
	 * For other cases, do not forget to stop the propagation.
	 * Note2: this is currently the only way to make links in rows (and may stay like that)
	 */
	onClick?: MouseEventHandler<HTMLTableRowElement>;
}

/** A Row of data for a Table */
export function RowData<T, K extends DataTableColumnId>(
	props: RowDataProps<T, K>,
) {
	const { className = "", clickable = true, columns, data, onClick } = props;

	const hasClickEvent = !!(onClick && clickable);
	const onRowClick: MouseEventHandler<HTMLTableRowElement> = hasClickEvent
		? event => {
				if ((event.target as HTMLElement)?.tagName === "A") {
					return;
				}

				onClick(event);
			}
		: () => void 0;

	return (
		<tr
			className={classnames(
				"row-data",
				{ clickable: hasClickEvent },
				className,
			)}
			onClick={onRowClick}
		>
			{columns.map(({ cell, id }) => (
				<td key={id} data-testid={`table/td/${id}`}>
					{cell(data)}
				</td>
			))}
		</tr>
	);
}
