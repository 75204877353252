import styled from "@emotion/styled";
import { CustomerWithPlansDto } from "~/common/customer/dtos";

import { ROUTES } from "../../../../../services/routes";
import {
	Button,
	ButtonVariants,
} from "../../../../ui-form/components/Button/Button";

/** Max number of plan showed in the cell */
export const MAX_SHOWN_CELL_PLAN = 3;

/** Props for {@link PlansCell} */
export interface PlansCellProps {
	/** The plans to show */
	plans: CustomerWithPlansDto["plans"];
}
/**
 * Show customer's plans in a table cell.
 *
 * @param props to generate the component
 */
export function PlansCell(props: PlansCellProps) {
	const { plans } = props;

	const y = plans.length - MAX_SHOWN_CELL_PLAN;

	return (
		<CellContainer data-testid="customers/cell/plans">
			{plans.slice(0, MAX_SHOWN_CELL_PLAN).map(({ _id, year }) => (
				<Button
					key={_id}
					href={ROUTES.plans.children.one.url(_id)}
					label={year.toString()}
					variant={ButtonVariants.TEXT}
				/>
			))}

			{0 < y && <span>+{y}</span>}
		</CellContainer>
	);
}

const CellContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;
