import { OmitKnown } from "@nna/core";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { UserDto, UserQueryDto } from "~/common/user/dtos";

import { userRepository } from "../../../services/api/repositories";
import { PeopleNameWithAvatar } from "../../people/components";
import { ModelSelects } from "../../ui-form/components";
import { AutocompleteOption } from "../../ui-form/components/AutoComplete";

/** Existing filter object */
export type UserSelectQueryFilter = NonNullable<UserQueryDto["filter"]>;

/** Query data when searching for users */
export interface UserSelectQuery extends Pick<UserQueryDto, "limit" | "order"> {
	/** Complete the filter property of the executed request */
	filter?: (search: string) => UserSelectQueryFilter;
}

/** Props for {@link UserSelect} */
export interface UserSelectProps<
	Multiple extends boolean | undefined = false,
	DisableClearable extends boolean | undefined = false,
> extends OmitKnown<
		ModelSelects.ModelSelectProps<UserDto, Multiple, DisableClearable>,
		"queryOptions" | "queryValues"
	> {
	query?: UserSelectQuery;
}

/** Input for user search & select */
export function UserSelect<
	Multiple extends boolean = false,
	DisableClearable extends boolean = false,
>(props: UserSelectProps<Multiple, DisableClearable>) {
	const { query = {}, ...selectProps } = props;

	const { t } = useTranslation();

	const queryOptions = useMemo<
		ModelSelects.ModelSelectQueryOptions<UserDto>
	>(() => {
		const {
			filter = () => ({}),
			limit = 15,
			order = [{ name: { first: "asc" } }, { name: { last: "asc" } }],
		} = query;

		return search => {
			const $like = `%${search}%`;
			return userRepository.findAndCount({
				filter: {
					$and: [filter(search)],
					$or: [
						{ name: { first: { $like } } },
						{ name: { last: { $like } } },
						{ email: { $like } },
					],
				},
				limit,
				order,
			});
		};
	}, [query]);
	const queryValues = useCallback<
		ModelSelects.ModelSelectQueryValues<UserDto>
	>(
		ids =>
			userRepository.findAndCount({
				filter: { _id: { $in: ids.slice() } },
			}),
		[],
	);

	return (
		<ModelSelects.ModelSelect
			placeholder={t("features.user.select-counselor")}
			{...selectProps}
			getOptionLabel={({ name }) =>
				[name.first, name.last].filter(str => !!str).join(" ")
			}
			queryOptions={queryOptions}
			queryValues={queryValues}
			renderOption={(props, { _id, name }) => (
				<AutocompleteOption
					{...props}
					key={_id}
					data-testid={`user/select/${_id}`}
				>
					<PeopleNameWithAvatar name={name} />
				</AutocompleteOption>
			)}
		/>
	);
}
