import styled from "@emotion/styled";
import { CustomerWithPlansDto } from "~/common/customer/dtos";

import { PeopleNameWithAvatar } from "../../../../people/components";
import { theme } from "../../../../ui-layout/styles/theme";

const CellContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 4px;
`;

const CustomerId = styled.span`
	color: ${theme.getCssVar("palette-blue-primary")};
	font-size: 12px;
	line-height: 15.6px;
`;

/** Props for {@link CustomerCell} */
export interface CustomerCellProps {
	/** The customer to show */
	customer: Pick<CustomerWithPlansDto, "_id" | "name">;
}
/**
 * TODO
 *
 * @param props to generate the component
 */
export function CustomerCell(props: CustomerCellProps) {
	const { customer } = props;
	const { _id, name } = customer;

	return (
		<CellContainer data-testid="customers/cell/customer">
			<PeopleNameWithAvatar name={name} />

			<CustomerId>{_id}</CustomerId>
		</CellContainer>
	);
}
