import { UserDto } from "~/common/user/dtos";

import { PeopleNameWithAvatar } from "../../../../people/components";

/** Props for {@link CounselorCell} */
export interface CounselorCellProps {
	/** The counselor to show */
	counselor: Pick<UserDto, "name">;
}
/**
 * Component to view a counselor in a (table) cell
 *
 * @param props to generate the component
 */
export function CounselorCell(props: CounselorCellProps) {
	const { counselor } = props;
	const { name } = counselor;

	return (
		<PeopleNameWithAvatar
			data-testid="customers/cell/counselor"
			name={name}
		/>
	);
}
