import { useCallback, useMemo } from "react";

import { DataTable, DataTableColumn, DataTableProps } from "./DataTable";
import { HeaderSortable, HeaderSortableProps } from "./HeaderSortable";
import { DataTableColumnId } from "./common";
import * as TableSortState from "./data-table.sort-state";

// /!\ ATTENTION /!\
// If this values changes, watch where components are used for compatibility.
const SORT_DEFAULTS = {
	/** Default value for {@link DataTableSortableProps.allowMultiSorting} */
	allowMultiSorting: true,
	/** Default value for {@link DataTableSortColumn.sortable} */
	columnSortable: true,
} as const;

/** Table column definition for {@link DataTableSortableProps} */
export interface DataTableSortColumn<T, K extends DataTableColumnId>
	extends DataTableColumn<T, K> {
	/**
	 * Wrapper around the default header.
	 * Only used when the column is {@link sortable}
	 *
	 * @default HeaderSortable
	 */
	headerSortWrapper?: typeof HeaderSortable;
	/**
	 * Is this column sortable.
	 * When disabled, no {@link headerSortWrapper} is used
	 *
	 * @default true (see default {@link SORT_DEFAULTS})
	 */
	sortable?: boolean;
}

/** Props for {@link DataTableSortable} */
export interface DataTableSortableProps<T, K extends TableSortState.StateId>
	extends DataTableProps<T, K> {
	/**
	 * Allow multi-sorting.
	 * When disabled, only the first element of {@link sorting} is used ans notified by {@link onSortingChange}
	 *
	 * @default true
	 */
	allowMultiSorting?: boolean;
	/** Extended columns definitions */
	columns: ReadonlyArray<DataTableSortColumn<T, K>>;
	/** Callback when a header change its `SortState` */
	onSortingChange?: (sorts: Array<TableSortState.State<K>>) => void;
	/** State of the "sorting", used to default the header's `SortState` */
	sorting: ReadonlyArray<TableSortState.State<K>>;

	// TODO: desc -> asc or asc -> desc (and by custom directions and by column ?)
}

/**
 * A table element with sort capabilities.
 *
 * Extension of {@link DataTable}
 *
 * @see DataTable
 * @param props to create the component
 */
export function DataTableSortable<T, K extends TableSortState.StateId>(
	props: DataTableSortableProps<T, K>,
) {
	const {
		allowMultiSorting = SORT_DEFAULTS.allowMultiSorting,
		columns,
		onSortingChange,
		sorting: sortingRaw,
		...baseProps
	} = props;

	const sorting = useMemo(
		// Only keep one value when no-multiple
		() => (allowMultiSorting ? sortingRaw : sortingRaw.slice(0, 1)),
		[allowMultiSorting, sortingRaw],
	);

	const nextSort = useCallback(
		// Updates the next sorting state given column id
		(id: K) => {
			if (!onSortingChange) {
				return;
			}

			const updated = TableSortState.update(sorting, { id });
			onSortingChange(
				allowMultiSorting ? updated.slice() : updated.slice(0, 1),
			);
		},
		[allowMultiSorting, onSortingChange, sorting],
	);

	// Columns for the base component
	type TblColumns = DataTableProps<T, K>["columns"];

	// Will wrap the default header with a sort-wrapper
	const buildSortableHeader = useCallback(
		({
			header: Header,
			headerSortWrapper: HeaderWrapper = HeaderSortable,
			...column
		}: (typeof columns)[number]): TblColumns[number] => {
			const position = sorting.findIndex(({ id }) => id === column.id);
			const sortState: HeaderSortableProps["sortState"] =
				position < 0
					? null
					: { direction: sorting[position].direction, position };

			return {
				...column,
				header: () => (
					<HeaderWrapper
						sortNext={() => nextSort(column.id)}
						sortState={sortState}
						sortedTotal={sorting.length}
					>
						<Header />
					</HeaderWrapper>
				),
			};
		},
		[nextSort, sorting],
	);

	const columnsWithSort = useMemo<TblColumns>(() => {
		if (
			columns.every(
				({ sortable = SORT_DEFAULTS.columnSortable }) => !sortable,
			)
		) {
			// Nothing to do, all columns are un-sortable
			return columns;
		}

		return columns.map(
			({ sortable = SORT_DEFAULTS.columnSortable, ...column }) =>
				sortable ? buildSortableHeader(column) : column,
		);
	}, [columns, buildSortableHeader]);

	return (
		<DataTable
			data-testid="table-sortable"
			{...baseProps}
			columns={columnsWithSort}
		/>
	);
}
