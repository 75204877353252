import styled from "@emotion/styled";
import { TdHTMLAttributes } from "react";

import { theme } from "../../../ui-layout/styles/theme";
import { Pagination, PaginationProps } from "../Pagination";

/** A default {@link DataTable} pageSize value, that can be used where pagination is needed */
export const PAGINATION_PAGESIZE_DEFAULT = 20;
export const PAGINATION_PAGE_DEFAULT = 1;

/** Props for {@link PaginationFooter} */
interface PaginationFooterProps
	extends PaginationProps,
		Pick<TdHTMLAttributes<never>, "colSpan"> {}

/**
 * A data-table footer that displays the {@link Pagination} component
 */
export function PaginationFooter(props: PaginationFooterProps) {
	const { colSpan, ...paginationProps } = props;
	return (
		<tfoot>
			<tr>
				<CellStyled colSpan={colSpan}>
					<Pagination {...paginationProps} />
				</CellStyled>
			</tr>
		</tfoot>
	);
}

const CellStyled = styled.td`
	background: ${theme.palette.white.primary} !important;
	padding: 0 !important;
`;
