import { TFunction } from "next-i18next";
import { CustomerWithPlansDto } from "~/common/customer/dtos";

import * as Cells from "./cells";
import { DataTableSortColumn } from "../../../ui-atoms/components/data-table";

// Base type for raw definition creation
type ColumnBase<K extends string> = DataTableSortColumn<
	CustomerWithPlansDto,
	K
>;
type ColumnBaseBuilder<K extends string> = (t: TFunction) => ColumnBase<K>;

/** Base column definition for {@link Columns} */
const columnsBase = {
	// `id` is filled below with its key

	city: t => ({
		cell: ({ address }) => `${address.postcode} ${address.city}`,
		header: () => t("entity.customer.address.post+city"),
		id: "",
	}),
	counselor: t => ({
		cell: ({ counselor }) => Cells.CounselorCell({ counselor }),
		header: () => t("entity.customer.counselor"),
		id: "",
	}),
	customer: t => ({
		cell: customer => Cells.CustomerCell({ customer }),
		header: () => t("entity.customer._meta.names"),
		id: "",
	}),
	district: t => ({
		cell: ({ address }) => address.district,
		header: () => t("entity.people.address.district"),
		id: "",
	}),
	email: t => ({
		cell: ({ email }) => email,
		header: () => t("entity.people.email"),
		id: "",
	}),
	phone: t => ({
		cell: ({ phone }) => phone,
		header: () => t("entity.customer._list.phone"),
		id: "",
	}),
	phone2: t => ({
		cell: ({ phoneSecondary }) => phoneSecondary,
		header: () => t("entity.customer._list.phone2"),
		id: "",
	}),
	plans: t => ({
		cell: ({ plans }) => Cells.PlansCell({ plans }),
		header: () => t("entity.customer._list.last-plans"),
		id: "",
	}),
} as const satisfies Record<string, ColumnBaseBuilder<"">>;

type ColumnsBase = typeof columnsBase;

/** Id of a customer column */
export type ColumnId = keyof ColumnsBase;
/** Known column Ids */
export const COLUMN_IDS = Object.keys(columnsBase) as readonly ColumnId[];

/** Definition of a customer column */
export type ColumnDefinition<K extends ColumnId = ColumnId> =
	DataTableSortColumn<CustomerWithPlansDto, K>;
/** Builder (with translation `t`) for a customer column */
export type ColumnBuilder<K extends ColumnId = ColumnId> = ColumnBaseBuilder<K>;

/** Type for {@link Columns} record definition*/
export type Columns = { [K in ColumnId]: ColumnBuilder<K> };
/**
 * Known customer columns definition to use for "customer-table".
 *
 * To be used as a configuration for the table
 *
 * @example
 * const columns = (short ? [Columns.customer] : [Columns.customer, Columns.email])
 * 	.map(build => build(t));
 * return (<Table columns=[columns]) />;
 */
export const Columns = Object.fromEntries(
	Object.entries<ColumnsBase[ColumnId]>(columnsBase).map(([id, def]) => [
		id,
		((...params) => ({
			...def(...params),
			id,
		})) satisfies ColumnBaseBuilder<string>,
	]),
) as Columns;
