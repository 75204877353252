import { styled } from "@mui/joy";

import { Icon, IconNameKey } from "./Icons/Icon";
import { textBody, textH3 } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/** Props for {@link EmptyState} */
export interface EmptyStateProps {
	/** Actions (typically an array of Buttons) displayed at the bottom of the state */
	actions?: React.ReactNode;
	/** The description of the empty state */
	description: React.ReactNode;
	/**
	 * The icon to display
	 *
	 * @default addDocument
	 */
	iconName?: IconNameKey;
	/** The title of the empty state */
	title: string;
}

/**
 * Component displayed in lists when the list is empty
 *
 * @param props Props to generate the component
 */
export const EmptyState = (props: EmptyStateProps): JSX.Element => {
	const { actions, description, iconName = "addDocument", title } = props;

	return (
		<EmptyStateContainer data-testid="ui/empty-state">
			<Icon color={theme.palette.grey[45]} name={iconName} size={64} />

			<StateInformation>
				<h3 css={textH3} data-testid="ui/empty-state/title">
					{title}
				</h3>
				<p css={textBody} data-testid="ui/empty-state/description">
					{description}
				</p>
			</StateInformation>

			<StateActions data-testid="ui/empty-state/actions">
				{actions}
			</StateActions>
		</EmptyStateContainer>
	);
};

const EmptyStateContainer = styled("div")`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	margin: 64px 0;
`;

const StateInformation = styled("div")`
	display: flex;
	flex-direction: column;
	gap: 8px;
	text-align: center;
`;

const StateActions = styled("div")`
	display: flex;
	flex-wrap: no-wrap;
`;
