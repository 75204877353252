import styled from "@emotion/styled";
import { ReactNode } from "react";

import * as TableSortState from "./data-table.sort-state";
import { theme } from "../../../ui-layout/styles/theme";
import { Icon } from "../Icons/Icon";

const Wrapper = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 4px;
`;

const IconWrapper = styled.div`
	display: flex;
	flex-direction: row;

	span {
		font-size: ${theme.fontSize.xxs};
	}
`;

/** Props for {@link HeaderSortable} */
export interface HeaderSortableProps {
	/** Children element. The normal header content */
	children: ReactNode;
	/** Function to call to run the "next-sort" default handling */
	sortNext: () => void;
	/**
	 * State of the sort in the column.
	 * `null` when not currently sorted
	 */
	sortState: {
		/** Direction currently applied to the column */
		direction: TableSortState.Direction;
		/** Position this order is applied */
		position: number;
	} | null;
	/** Total of element sorted */
	sortedTotal: number;
}

/**
 * Element wrapper for a sortable header in a table.
 * It is the default header of `TableSortable`.
 */
export function HeaderSortable(props: HeaderSortableProps) {
	const { children, sortNext, sortState, sortedTotal } = props;
	return (
		<Wrapper data-testid="header-sortable" onClick={sortNext}>
			{children}
			{sortState && (
				<IconWrapper data-testid="header-sortable/state">
					{sortState.direction === "asc" ? (
						<Icon data-testid="header-sortable/up" name="caretUp" />
					) : (
						<Icon
							data-testid="header-sortable/down"
							name="caretDown"
						/>
					)}

					{1 < sortedTotal && <span>{sortState.position + 1}</span>}
				</IconWrapper>
			)}
		</Wrapper>
	);
}
