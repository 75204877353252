import { CustomerQueryDto } from "~/common/customer/dtos";

import { ColumnId } from "./columns";
import { DataTableSortState } from "../../../ui-atoms/components/data-table";

/** Dict of "order-queries" that can result from an order from a sorted column */
export const COLUMN_ORDERER: Readonly<
	Record<
		ColumnId,
		(
			order: DataTableSortState.Direction,
		) => NonNullable<CustomerQueryDto["order"]>
	>
> = {
	city: order => [{ address: { city: order, postcode: order } }],
	counselor: order => [
		// First-name then last-name
		{ counselor: { name: { first: order } } },
		{ counselor: { name: { last: order } } },
	],
	customer: order => [{ name: { first: order } }, { name: { last: order } }],
	district: order => [{ address: { district: order } }],
	email: order => [{ email: order }],
	phone: order => [{ phone: order }],
	phone2: order => [{ phoneSecondary: order }],
	plans: order => [{ plans: { year: order } }],
};
