import styled from "@emotion/styled";

import { DataTableElementWrapper } from "./common";

// Default elements with style

/** Default `thead` */
export const THeadDefault: DataTableElementWrapper = styled.thead`
	tr {
		height: 64px;
	}

	tr th {
		/** All the !important below are here to override joy styles */
		background: none !important;
		vertical-align: middle !important;

		:first-of-type {
			padding-left: 24px !important;
		}
	}
`;

/** Default `tbody` */
export const TBodyDefault: DataTableElementWrapper = styled.tbody`
	tr {
		height: 64px;

		.row-data.clickable {
			cursor: pointer;
		}
	}

	tr td {
		:first-of-type {
			padding-left: 24px !important;
		}
	}
`;
