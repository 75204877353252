import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { ChangeEventHandler, useEffect, useState } from "react";

import { Inputs } from "../../ui-form/components";
import {
	ButtonIcon,
	ButtonIconSizes,
} from "../../ui-form/components/Button/ButtonIcon";
import { textBody } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";
import { PagePagination } from "../utils/pagination";

/** Props for {@link Pagination} component */
export interface PaginationProps extends PagePagination {
	/** Called when Pagination changes the `page` */
	onPageChange?: (page: number) => void;
	/** Called when Pagination changes the `pageSize` (unused at the moment) */
	onPageSizeChange?: (pageSize: number) => void;
	/** Set to `true` if page controls should be hidden when useless (e.g. pageSize === 1) */
	showControlsOnlyWhenNeeded?: boolean;
	/** The total number of items */
	total: number;
}

/** Generic pagination component */
export function Pagination(props: PaginationProps) {
	const { t } = useTranslation();

	const {
		onPageChange,
		page,
		pageSize,
		showControlsOnlyWhenNeeded = false,
		total,
	} = props;

	const numberOfPages = Math.ceil(total / pageSize);
	const firstItemOfCurrentPage = (page - 1) * pageSize + 1;
	const lastItemOfCurrentPage = Math.min(
		firstItemOfCurrentPage + pageSize - 1,
		total,
	);

	const [inputValue, setInputValue] = useState<number | string>(page);

	const disablePrevious = page === 1;
	const disableNext = total <= page * pageSize;

	const onPreviousPageClick = () => {
		const newPage = page - 1;
		setInputValue(newPage);
		if (onPageChange) {
			onPageChange(newPage);
		}
	};

	const onNextPageClick = () => {
		const newPage = page + 1;
		setInputValue(newPage);
		if (onPageChange) {
			onPageChange(newPage);
		}
	};

	useEffect(() => {
		// If the current page was (potentially) reset, we ensure the input value is reset too
		// (Note: this behavior is likely too specific to be hardcoded like this)
		if (page === 1) {
			setInputValue(page);
		}
	}, [page]);

	const onPaginationInputChange: ChangeEventHandler<HTMLInputElement> = e => {
		const newPage = parseInt(e.target.value, 10);

		// If the value is empty (== empty input) or isNaN, we only update the input state
		if (e.target.value === "" || Number.isNaN(newPage)) {
			setInputValue("");
			return;
		}

		// If the value is out of pagination range, we don't allow the change
		if (newPage < 1 || numberOfPages < newPage) {
			return;
		}

		// If the value is valid, we update the input and the page states
		setInputValue(newPage);
		if (onPageChange) {
			onPageChange(newPage);
		}
	};

	const showControl = showControlsOnlyWhenNeeded ? 1 < numberOfPages : true;

	return (
		<PaginationContainer>
			<div>
				{firstItemOfCurrentPage}-{lastItemOfCurrentPage}{" "}
				{t("common.pagination.on")} {total}
			</div>

			{showControl && (
				<Controls>
					<ButtonIcon
						disabled={disablePrevious}
						name="keyboardArrowLeft"
						noBackground
						onClick={onPreviousPageClick}
						size={ButtonIconSizes.SIZE40}
					/>
					<Inputs.Styles.InputSmall
						onChange={onPaginationInputChange}
						type="number"
						value={inputValue}
					/>
					<span>/ {numberOfPages}</span>
					<ButtonIcon
						disabled={disableNext}
						name="keyboardArrowRight"
						noBackground
						onClick={onNextPageClick}
						size={ButtonIconSizes.SIZE40}
					/>
				</Controls>
			)}
		</PaginationContainer>
	);
}

const PaginationContainer = styled("div")`
	align-items: center;
	color: ${theme.palette.blue.primary};
	display: flex;
	justify-content: space-between;
	padding: 8px 24px;
	${textBody}
`;

const Controls = styled("div")`
	align-items: center;
	display: flex;
	gap: 4px;
`;
