import * as joy from "@mui/joy";
import { OmitKnown, UnshiftParameters } from "@nna/core";
import { useTranslation } from "next-i18next";
import type { ReactNode } from "react";
import type React from "react";

import { RowColumn, RowData, RowDataDef, RowDataProps } from "./RowData";
import { DataTableColumnId, DataTableElementWrapper } from "./common";
import { TBodyDefault, THeadDefault } from "./defaults";
import { Layout } from "../../../ui-layout";
import { TableCustomProps, TableCustom } from "../TableCustom";

/** Table column definition for {@link DataTableProps} */
export interface DataTableColumn<T, K extends DataTableColumnId>
	extends RowColumn<T, K> {
	/** Table header cell for this column */
	header: () => ReactNode;
}

/** Data for {@link DataTableProps} */
export interface DataTableDataDef<T> extends RowDataDef<T> {
	/** Unique key (for react) of the data */
	key: DataTableColumnId;
}

interface DataTableEmptyBodyBase<T extends string> {
	/** Mode of the empty body */
	mode: T;
}
/** A {@link DataTableEmptyBody} completely custom */
export interface DataTableEmptyBodyCustom
	extends DataTableEmptyBodyBase<"custom"> {
	body: React.FC<{
		/** Number of shown columns */
		nColumns: number;
	}>;
}
/** A {@link DataTableEmptyBody} that takes the full width of the table */
export interface DataTableEmptyBodyFull extends DataTableEmptyBodyBase<"full"> {
	content: () => ReactNode;
}
/** The body to shown in {@link DataTable} when there is no data */
export type DataTableEmptyBody =
	| DataTableEmptyBodyCustom
	| DataTableEmptyBodyFull;

/** Props for {@link DataTable} */
export interface DataTableProps<T, K extends DataTableColumnId>
	extends OmitKnown<joy.TableProps, "stripe">,
		TableCustomProps {
	/** Columns definitions */
	columns: ReadonlyArray<DataTableColumn<T, K>>;
	/** The data to iterate and show in the lines */
	data: ReadonlyArray<DataTableDataDef<T>>;
	/** Content to show when {@link data} is empty */
	emptyBody?: DataTableEmptyBody;
	/**
	 * On the click of a row
	 *
	 * @see {RowDataProps.onClick}
	 */
	onRowClick?: UnshiftParameters<
		NonNullable<RowDataProps<T, K>["onClick"]>,
		[data: T]
	>;
	/**
	 * Override `tbody` component.
	 * Can be used to style itself and its `tr`s.
	 * 	However, be aware that joy manages a lot of the style already
	 *
	 * @default see {@link TBodyDefault}
	 *
	 * @example
	 * const MyTBody = styled.tbody`background: yellow`;
	 * const MyTable = <Table tbody={MyTBody} />;
	 */
	tbody?: DataTableElementWrapper;
	/**
	 * Override `tfoot` component
	 *
	 * @default None (nothing shown by default)
	 */
	tfoot?: React.FC;
	/**
	 * Override `thead` component
	 * Can be used to style itself and its `tr`.
	 * 	However, be aware that joy manages a lot of the style already
	 *
	 * @default see {@link THeadDefault}
	 *
	 * @example
	 * const MyTHead = styled.thead`background: yellow`;
	 * const MyTable = <Table thead={MyTHead} />;
	 */
	thead?: DataTableElementWrapper;
}

/**
 * (Data-)Table component to show some data.
 *
 * Uses {@link joy.Table} as first component.
 *
 * @param props to create the component
 */
export function DataTable<T, K extends DataTableColumnId>(
	props: DataTableProps<T, K>,
) {
	// TODO: remove `joy.Table` and the caller must set it as the parent ?
	//	Or this is a TableContent Component and create another component `Table` ?

	const { t } = useTranslation();

	const {
		$hover,
		$stickyFirstColumn,
		$stripe,
		columns,
		data,
		emptyBody = {
			content: () => t("component.table.empty-data"),
			mode: "full",
		},
		onRowClick,
		tbody: TBody = TBodyDefault,
		tfoot: TFoot,
		thead: THead = THeadDefault,
		...joyProps
	} = props;

	// TODO: the `thead` and `tbody` could also be separated for better composition on special tables
	return (
		<TableCustom
			data-testid="table"
			{...joyProps}
			$hover={$hover && !!data.length}
			$stickyFirstColumn={$stickyFirstColumn}
			$stripe={$stripe}
			// Be sure to disable them
			hoverRow={false}
			stripe=""
		>
			<THead>
				<tr>
					{columns.map(({ header, id }) => (
						<th key={id} data-testid={`table/th/${id}`}>
							{header()}
						</th>
					))}
				</tr>
			</THead>

			<TBody>
				{data.map(({ data, key, ...rowProps }) => (
					<RowData
						data-testid={`table/tr/${key}`}
						{...rowProps}
						key={key}
						columns={columns}
						data={data}
						onClick={
							onRowClick ? e => onRowClick(data, e) : undefined
						}
					/>
				))}

				{data.length === 0 && (
					<tr className="row-empty" data-testid={`table/tr/empty`}>
						{emptyBody.mode === "full" ? (
							<td colSpan={columns.length}>
								<Layout.Centered>
									{emptyBody.content()}
								</Layout.Centered>
							</td>
						) : (
							emptyBody.body({ nColumns: columns.length })
						)}
					</tr>
				)}
			</TBody>

			{TFoot && <TFoot />}
		</TableCustom>
	);
}
